@import "./assets/css/theme/theme";
@import "remixicon/fonts/remixicon.css";
// @import "~bootstrap/dist/css/bootstrap.min.css";

@import "~@ng-select/ng-select/themes/default.theme.css";

* {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

body::-webkit-scrollbar {
  width: 10px !important;
}

body::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(25, 147, 147, 0.1) !important;
}

body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(25, 147, 147, 0.2) !important;
}
@import "bootstrap/scss/bootstrap";